@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@font-face {
    font-family: "FiraSans";
    font-style: italic;
    font-weight: normal;
    src: url("./fonts/FiraSans-Italic.ttf");
}

@font-face {
    font-family: "FiraSans";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/FiraSans-Regular.ttf");
}

@font-face {
    font-family: "FiraSans";
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/FiraSans-Bold.ttf");
}

@font-face {
    font-family: "FiraSans";
    font-style: italic;
    font-weight: bold;
    src: url("./fonts/FiraSans-BoldItalic.ttf");
}

@font-face {
    font-family: "FiraSans Medium";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/FiraSans-Medium.ttf");
}

@font-face {
    font-family: "FiraSans Medium";
    font-style: italic;
    font-weight: normal;
    src: url("./fonts/FiraSans-MediumItalic.ttf");
}


@font-face {
    font-family: "FiraMono";
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/FiraMono-Bold.ttf");
}

@font-face {
    font-family: "FiraMono";
    font-style: bolder;
    src: url("./fonts/FiraMono-Medium.ttf");
}

@font-face {
    font-family: "FiraMono";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/FiraMono-Regular.ttf");
}

@font-face {
    font-family: "FiraCode";
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/FiraCode-Bold.ttf");
}

@font-face {
    font-family: "FiraCode";
    font-style: bolder;
    src: url("./fonts/FiraCode-Medium.ttf");
}

@font-face {
    font-family: "FiraCode";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/FiraCode-Retina.ttf");
}

@font-face {
    font-family: "Fantasque Sans Mono";
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/FantasqueSansMono-Bold.ttf");
}

@font-face {
    font-family: "Fantasque Sans Mono";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/FantasqueSansMono-Regular.ttf");
}

@font-face {
    font-family: "Fantasque Sans Mono";
    font-style: italic;
    font-weight: bold;
    src: url("./fonts/FantasqueSansMono-BoldItalic.ttf");
}

@font-face {
    font-family: "Fantasque Sans Mono";
    font-style: italic;
    font-weight: normal;
    src: url("./fonts/FantasqueSansMono-Italic.ttf");
}


@font-face {
    font-family: "JetBrainsMono Nerd Font Mono";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/JetBrains Mono Regular Nerd Font Complete Mono.ttf");
}



/*body, h1*/
@font-face {
    font-family: 'Patric Hand';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/PatrickHand-Regular.ttf')
}

@font-face {
    font-family: 'Specjal Elite';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/SpecialElite-Regular.ttf')
}



li {
  padding-left: 16px;
}

li::before {
  content: "⁕"; /* Insert content that looks like bullets */
  padding-right: 8px;
  color:#0080ff; /* Or a color you prefer */
}

/*
font-family: 'Fira Sans', sans-serif;
font-family: 'Ubuntu', sans-serif;
font-family: 'PT Sans', sans-serif;
font-family: 'Merriweather', serif;
font-family: 'Varela Round', sans-serif;
font-family: 'Ubuntu Condensed', sans-serif;
font-family: 'Gloria Hallelujah', cursive;
font-family: 'Patrick Hand', cursive;
font-family: 'Special Elite', cursive;
font-family: 'Arbutus Slab', serif;
font-family: 'Ubuntu Mono', monospace;

*/

    
/*body*/


/*@import url(https://fonts.googleapis.com/css?family=Crete+Round:400,400italic&subset=latin,latin-ext);*/


/*body H5*/


/*h1 h2 h3*/

@font-face {
    font-family: "Bree Serif";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/BreeSerif-Regular.ttf");
}

@font-face {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/ubuntu-regular.ttf");
}


/*body*/

@font-face {
    font-family: "Crete Round";
    font-style: normal;
    src: url("./fonts/CreteRound-Regular.ttf");
}


/*h4, h5*/

@font-face {
    font-family: "Crete Round";
    font-style: italic;
    src: url("./fonts/CreteRound-Italic.ttf");
}


/*cite, q, blockquote*/

@font-face {
    font-family: "ArbutusSlab Regular";
    font-style: italic;
    src: url("./fonts/ArbutusSlab-Regular.ttf");
}


/*h6*/

@font-face {
    font-family: "RobotoSlab";
    font-style: normal;
    font-weight: thin;
    src: url("./fonts/RobotoSlab-Thin.ttf");
}

@font-face {
    font-family: "RobotoSlab";
    font-style: normal;
    font-weight: lighter;
    src: url("./fonts/RobotoSlab-Light.ttf");
}

@font-face {
    font-family: "RobotoSlab";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/RobotoSlab-Regular.ttf");
}

@font-face {
    font-family: "RobotoSlab";
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/RobotoSlab-Bold.ttf");
}



body {
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Crete Round';
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", */
    /* "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", */
    /* sans-serif; */
    -webkit-font-smoothing: antialiased;
    position: relative;
    font-size: 22px;
    line-height: 125%;
    font-style: normal;
    overflow-y: overlay;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

 :root {
    --primary-color: #043a5a;
    --dark-color: #333;
    --light-color: #f4f4f4;
    --danger-color: #dc3545;
    --success-color: #c0bfbd;
    --xxx-color: #b5b8b7;
}


/**************Navbar ******************/

.navbar,
.footer {
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    padding: 0;
    /* z-index: 1; */
    width: 100%;
    color: var(--light-color);
    background-color: var(--primary-color);
    margin-bottom: 1rem;
    height: fit-content;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center
}

.logo span {
    color: rgb(255, 57, 22)
}

.navbar ul {
    display: flex;
    flex-direction: column;
}

.navbar i {
    padding: 0 0.25rem
}


h1 {
    padding: 0 1rem;
    font-family: 'Patric Hand', serif;
}

/*
a {
    text-decoration: none;
    color: var(--light-color)
}

li i:hover,
a:hover {
    opacity: 0.5;
    cursor: pointer;
}
*/


/* **************app ************************ */

.app-container {
    max-width: 90rem;
    margin: auto;
}

.main {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 0;
    margin: 0;
}


/* ****************** GuestForm************** */

form {
    display: flex;
    flex-direction: column;
    position: relative;
}

.invite-section {
    flex-basis: 33%;
}

.invite-section h1 {
    color: var(--primary-color);
    padding: 0;
    margin: 1rem 0
}

form input[type=text],
form input[type=email],
form input[type=password] {
    border: none;
    height: 2.5rem;
    font-size: 1.2rem;
    border-bottom: solid 1px gray;
}

form input[type=text]:focus,
form input[type=email]:focus,
form input[type=password]:focus {
    outline: none !important;
    border-bottom: 1px solid var(--primary-color);
}

.options-label {
    display: block;
    padding-top: 1rem;
    color: var(--primary-color);
    font-weight: bold
}

.options {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
}

.options label {
    cursor: pointer;
}

.options label:hover {
    color: rgb(150, 150, 150);
    transition: all 0.3s ease 0s;
}

.options i {
    margin-right: 0.7rem;
    transition: all 0.3s ease 0s;
}



/* .btn {
  display: inline-block;
  background: var(--primary-color);
  color:var(--light-color);
  padding: 0.6rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in;
  outline: none;
    margin-top:1rem;
      }
    .btn:hover{
    opacity: 0.9;
  }
 */


/* ************* toggel *************************** */

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-left: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .3s;
    transition: .3s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: -10px;
    bottom: -4px;
    border: 1px solid rgb(226, 225, 225);
    background-color: rgb(241, 241, 241);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: var(--primary-color);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--primary-color);
}

input:checked+.slider:before {
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(32px);
}


/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


/************************************************************************* */

.lead {
    color: var(--primary-color);
    margin-bottom: 2.5rem;
    margin-top: 0.7rem
}

.search {
    padding: 0.3rem 3rem;
    border-radius: 20px;
    border: 1px solid #ccc;
}

.search:focus {
    outline: none !important;
}

.search-icon {
    margin-left: -2rem;
    display: inline;
    color: #ccc;
    z-index: 100;
}


/********************* counter tabel styles ************** */
    /* border-collapse: collapse; */

    table {

    width: 100%;
    color: var(--primary-color);
    margin: 0;
    border-width: 2px;
    border-color: black;
    border-collapse: unset; 
}

td,
th {
    text-align: center;
    padding: 0.7rem 1.6rem;
}

th {
    text-align: left;
}

tr:nth-child(odd) {
    background-color: var(--light-color);
}

td {
    transition: all 0.3s ease 0s;
}


/* ************* radio button *******************/

/* .container { */
    /* display: block; */
    /* position: relative; */
    /* padding-left: 25px; */
    /* margin-bottom: 12px; */
    /* cursor: pointer; */
    /* font-size: 1rem; */
    /* -webkit-user-select: none; */
    /* -moz-user-select: none; */
    /* -ms-user-select: none; */
    /* user-select: none; */
/* } */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: #eee;
    border-radius: 50%;
    transition: all 0.2s ease 0s;
}

.container:hover input~.checkmark {
    background-color: #ccc;
}

.container input:checked~.checkmark {
    background-color: var(--primary-color);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked~.checkmark:after {
    display: block;
}

.container .checkmark:after {
    top: 6px;
    left: 6px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
}


/* *****************guests style  **********************/

.guests {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 90%;
    margin: auto;
    margin-top: 3.5rem;
    padding: 1rem
}


/****************************** guest card **********************/

.guest-card {
    color: var(--primary-color);
    background: var(--light-color);
    margin: 0.7rem 0;
    position: relative;
    max-width: 26rem;
    line-height: 1.6;
    margin-right: 1rem;
}

.card-head {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background: #ccc;
    padding: 0.2rem
}

.card-head input[type="checkbox"] {
    display: none
}

.card-head i,
.card-head label {
    color: rgb(150, 150, 150);
    padding: 0.1rem;
    cursor: pointer;
}

.card-head i:hover {
    color: var(--primary-color);
    opacity: 0.8;
    transition: all 0.1s ease 0s;
}

.card-head .remove:hover {
    color: red;
    opacity: 0.7;
    transition: all 0.1s ease 0s;
}

.confirm {
    color: var(--primary-color) !important;
}

.card-head label {
    margin-right: 0.2rem
}

.card-head button {
    border: none;
    padding: 0.4rem;
    cursor: pointer;
}

.card-head button:focus {
    outline: none
}

.card-body {
    padding: 1.2rem;
    position: relative;
    display: flex;
    flex-direction: column
}

.card-body h2 {
    margin-top: 1rem;
    padding: 0
}

.badge {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0.8rem;
    padding: 0.2rem 1rem;
    text-align: center;
    margin: 0.3rem;
    background: #ccc;
    color: #fff;
    border-radius: 2px;
}

.contact {
    display: flex;
    align-items: center
}

.contact p {
    font-weight: bold;
    padding: 0 0.5rem
}


/************************************ register and login  ***************************/

.register,
.login {
    color: var(--primary-color);
    text-align: center;
    width: 33%;
    margin: 12rem auto;
}

.clear {
    position: absolute;
    display: inline;
    color: var(--primary-color);
    background: rgb(189, 187, 187);
    bottom: 0;
    right: 0;
}

.no-guest {
    text-align: center;
    padding-right: 6rem;
    margin-top: 3rem
}

.question {
    color: #333;
    margin: 1.5rem 0
}

.question a {
    color: var(--primary-color);
}

.danger {
    background-color: rgb(207, 58, 58);
    width: 100%;
    margin-bottom: 0.3rem;
    margin-top: 0rem;
    text-transform: capitalize;
    padding: 0.5rem;
    cursor: initial;
    border: none;
    color: #fff;
    font: inherit
}

.danger span {
    float: right;
    cursor: pointer;
}

.red {
    background: rgb(248, 139, 139)
}

.green {
    background: rgb(128, 206, 128)
}

.seaGreen {
    background: rgb(107, 161, 197)
}

.btn { /* childs, stored */

    font-size: 1.3rem;
}




@media(min-width: 1200px) {

    .hide_when_horizontal {
        display:block;
    }
    .go_top_when_horizontal {
        margin:140px 0px 0px;
    }
    .register,
    .login {
        width: 40%
    }
    .main {
        flex-wrap: wrap;
        justify-content: space-around
    }
    table {
        flex: 1;
        margin: 0;
    }
    .invite-section {
        flex: 2;
        padding: 1rem;
        margin: 0 1rem;
    }

    .guests {
        margin-top: 0;
    }
    .guest-card h1 {
        font-size: 1.5rem
    }

    .btn { /* childs, stored */

        font-size: 1.2rem;
    }
    .nav-tabs {

        font-size: 0.65rem;
    }

}

@media(max-width:1200px) { /* for mobile screens at horizontal position */

    .hide_when_horizontal {
        display:none;
    }
    .go_top_when_horizontal {
        margin:0px;
        padding:0;
    }
    .guests {
        grid-template-columns: repeat(2, 1fr);
    }


    .btn { /* childs, stored */

        font-size: 1rem;
    }

}

@media(max-width:760px) {

    .hide_when_horizontal {
        display:block;
    }
    .go_top_when_horizontal {
        margin:140px 0px 0px;
    }
    .btn { /* childs, stored */

        font-size: 0.9rem;
    }


    .sm-hide {
        display: none;
    }
    .register,
    .login {
        width: 70%
    }
    .main,
    .filter,
    .guests{
        /* display: flex;
        flex-direction: column; */
        justify-content: center;
        align-items: center;

        flex-wrap: wrap;
        /*justify-content: space-around;*/
    }
    .invite-section {
        width: 100%;
        margin: auto
    }
    table {
        font-size: 12px;
       
    }
    .navbar {
        /* padding: 1rem 0; */
        font-size: 12px
    }
    .navbar h1 {
        font-size: 14px
    }
    .guest-card {
        width: 100%
    }

}



/* **************************Animation (Add After) *********************** */

.item-enter {
    opacity: 0;
}

.item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.item-exit {
    opacity: 1;
}

.item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}


    /* width: 100%; */
    /* display: flex;
    flex-direction: column; */
/* .row {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 9px;
    margin-bottom: 9px;

} */


p {
    margin-bottom: 0;
    display:block;
}

.dropdown-toggle {
    height: 2px;
    width: 6px;
    margin: 0px 0px;
    padding: 0px 0px;
    background-color: #ff7bff;
    border-color:#ff7bff;
    font-size: 0;
}
/* 
.tab-pane {
    line-height: 1.5;
} */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.2;
}

cite,
q,
blockquote {
    /*font-family: 'Vollkorn', serif;*/
    /*font-family: 'IM Fell DW Pica', serif;*/
    /*font-family: 'Neuton', serif;*/
    font-family: 'ArbutusSlab Regular';
    /* font-family: 'Specjal Elite', serif; */
    /* font-family: 'FiraSans Medium', serif; */
    
    font-size: 20px;
    line-height: 130%;
    font-weight: 400;
    color: #000030;
    background-color: white;
    overflow: auto; 
    /*white-space: nowrap;*/
    margin: 0.3rem;
   
    border: 3px solid #d8c810; 
    border-radius: 0.75rem; 
    padding: 1rem;
    
    /* for text line breaking */
    word-wrap: break-word;
    max-width: 100%;
    white-space: normal;
    /*display: inline-block;*/
}
    /* width: fit-content; */
blockquote {
    -webkit-box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.27);
}


h1 {
    font-family: 'Patric Hand', serif;
    /* font-family: 'Crete Round'; */
    /*'Bree Serif', serif;*/
    font-weight: bold;
    font-size: 2rem;
    font-style: italic;
    color: #0f4f5e;
    margin-bottom: .4rem;
    border-bottom: 2px solid #169179;
    box-shadow: 0px 8px 8px -4px rgba(0.5, 0.7, 0.9, 0.07);
    padding-top: 10px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
}

h2 {
    /*font-family: 'Averia Gruesa Libre', cursive;*/
    /* font-family: 'Bree Serif', serif; */
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 120%;
    /* margin: 20px 0 0 0; */
    /* color: #a03613; */
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
}


/*color_def*/

h3 {
    /* font-family: 'Averia Gruesa Libre', cursive; */
    /* font-family: 'FiraSans', serif; */
    /* font-family: 'Bree Serif', serif; */
    /* font-family: 'Crete Round', serif; */
    /* font-family: 'Ubuntu', serif; */
    /* font-family: 'RobotoSlab', serif; */
    font-weight: normal;
    /* font-style: italic; */
    font-size: 1.4rem;
    line-height: 100%;
    /* color: #ad2603; */
    /*text-shadow: #80a4af 2px 2px 2px;*/
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
}

h4 {
    font-family: 'Crete Round', serif;
    font-size: 1.2rem;
    font-weight: normal;
    font-style: italic;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
}


/*little headers*/

h5 {
    /*color: #1f5f6e;*/
    font-family: 'Crete Round', serif;
    font-style: italic;
    font-weight: normal;
    font-size: 1rem;
    line-height: 130%;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    /*text-shadow: #80a4af 2px 2px 2px;*/
}


h6 {
    font-family: 'RobotoSlab', serif;
    font-weight: light;
    font-size: 1.2rem;
    line-height: 130%;
    font-weight: normal;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
}


.navbar i {
    padding: 0 0.25rem;
}

.fa, .fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.fa, .fas, .far, .fal, .fad, .fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}



code {
    font-family: 'FiraCode';
    /* background-color: rgba(0,0,0,0); alpha=0 for remove background color from bootstrap */
    /* font-weight: normal; */
    /* font-size: 1.1rem; działa - tylko code */
    /*line-height: 130%;*/
}

pre {
    /* replace bootstrap settings in code.less */
    display: block;
    padding: 8px;
    margin: 10px 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #e0bcac;
    /*czubajHighLight*/
    border: 3px solid #ffdf8d;
    border-radius: 0.75rem;
}

.hljs {
    display: inline;
    overflow-x: auto;
    padding: 0;
    background: none;
}


/* for avoiding <br> */

.lines_br {
    white-space: pre;
}

.lines_br_wrap {
    white-space: pre-wrap;
}


/* .list-group-item.active {

    z-index: 0;
} */

   /* display:  -webkit-inline-box; */
.navbar-nav {

    width: -webkit-fill-available;
}

.tab-content {

    width: -webkit-fill-available;
}

/* ArticlesList: childs and stored tab --> STab  Navbar: NavLink */
.nav-link {

    padding:0;
    padding-left: 1rem;
    padding-right: 1rem; 
    color: white;
    /* box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1); */
 }

 .nav-link:hover {
 
    background-color: rgba(1, 1, 1, 0.35); /* ${czubajLight};  */
    /* color: #000000; */
} 


/* childs, stored */
/* .container { 
    padding-left: 30px;  
    
} */

iframe {
    border-width: 5px;
    border-style: solid;
    border-color: white;/*'#169179'  colorMidGreen */
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 22px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 2px 2px 22px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 2px 2px 22px 0px rgba(0, 0, 0, 0.27);
}


.react-dropdown-select-item {

    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #FCFEE8;
    /*czubajHighLight*/
    border: 1px solid rgb(187, 222, 35);
    border-radius: 1px;
    
}


/*STitleInputFormControl  (article title input)*/
.form-control[readonly] {

    background-color: #909090;
    border: 0px solid rgb(187, 222, 35);

}

/*
.list-group-item:first-child {

     border-radius: 0.4rem; 
    
}
*/

.list-group-item:last-child {

    border-radius: 0.4rem;
}
.list-group-item + .list-group-item {

    border-top-width: 2px;
}


/*.c1 {color: #7a7267;} .c2 {color: #ede0ce;} .c3 {color: #92b55f;} .c4 {color: #a0988e;} .c5 {color: #e8da5e;} .c6 {color: #487d76;}*/
.c1 {
    /* type */
    color: #fabd2f;
}

.c2 {
    /* keyword */
    color: #fe8019;
}

.c3 {
    /* identifier */
    color: #fbf1c7;
}

.c4 {
    /* comment */
    color: #928374;
}

.c5 {
    /* string */
    color: #b8bb26;
}

.c6 {
    /* number */
    color: #d3869b;
}

.c7 {
    /* operator */
    color: #83a598;
}

.c8 {
    /* delimiter */
    color: #a89984;
}

.c9 {
    /* separator */
    color: #fbf1c7;
}

.c10 {
    /* char */
    color: #b8bb26;
}

.c11 {
    /* pragma */
    color: #d3869b;
}

.c12 {
    /* conditional */
    color: #fe8019;
}

.c13 {
    /* statement */
    color: #fe8019;
}

.c14 {
    /* import */
    color: #8ec07c;
}

.c15 {
    /* declaration */
    color: #8ec07c;
}

.c16 {
    /* special */
    color: #8ec07c;
}
/*
generated from https://github.com/morhetz/gruvbox/blob/master/colors/gruvbox.vim -> prompt:
# dla elementów składni języka haskell podano definicje kolorów:
<definicja_kolorow>
hi! link haskellType GruvboxFg1
hi! link haskellIdentifier GruvboxFg1
hi! link haskellSeparator GruvboxFg1
hi! link haskellDelimiter GruvboxFg4
hi! link haskellOperators GruvboxBlue
hi! link haskellBacktick GruvboxOrange
hi! link haskellStatement GruvboxOrange
hi! link haskellConditional GruvboxOrange
hi! link haskellLet GruvboxAqua
hi! link haskellDefault GruvboxAqua
hi! link haskellWhere GruvboxAqua
hi! link haskellBottom GruvboxAqua
hi! link haskellBlockKeywords GruvboxAqua
hi! link haskellImportKeywords GruvboxAqua
hi! link haskellDeclKeyword GruvboxAqua
hi! link haskellDeriving GruvboxAqua
hi! link haskellAssocType GruvboxAqua
hi! link haskellNumber GruvboxPurple
hi! link haskellPragma GruvboxPurple
hi! link haskellString GruvboxGreen
hi! link haskellChar GruvboxGreen
</definicja_kolorow>

# Kolory są zdefiniowane jak poniżej:
<kolory>
let s:gb.dark0_hard  = ['#1d2021', 234]     " 29-32-33
let s:gb.dark0       = ['#282828', 235]     " 40-40-40
let s:gb.dark0_soft  = ['#32302f', 236]     " 50-48-47
let s:gb.dark1       = ['#3c3836', 237]     " 60-56-54
let s:gb.dark2       = ['#504945', 239]     " 80-73-69
let s:gb.dark3       = ['#665c54', 241]     " 102-92-84
let s:gb.dark4       = ['#7c6f64', 243]     " 124-111-100
let s:gb.dark4_256   = ['#7c6f64', 243]     " 124-111-100

let s:gb.gray_245    = ['#928374', 245]     " 146-131-116
let s:gb.gray_244    = ['#928374', 244]     " 146-131-116

let s:gb.light0_hard = ['#f9f5d7', 230]     " 249-245-215
let s:gb.light0      = ['#fbf1c7', 229]     " 253-244-193
let s:gb.light0_soft = ['#f2e5bc', 228]     " 242-229-188
let s:gb.light1      = ['#ebdbb2', 223]     " 235-219-178
let s:gb.light2      = ['#d5c4a1', 250]     " 213-196-161
let s:gb.light3      = ['#bdae93', 248]     " 189-174-147
let s:gb.light4      = ['#a89984', 246]     " 168-153-132
let s:gb.light4_256  = ['#a89984', 246]     " 168-153-132

let s:gb.bright_red     = ['#fb4934', 167]     " 251-73-52
let s:gb.bright_green   = ['#b8bb26', 142]     " 184-187-38
let s:gb.bright_yellow  = ['#fabd2f', 214]     " 250-189-47
let s:gb.bright_blue    = ['#83a598', 109]     " 131-165-152
let s:gb.bright_purple  = ['#d3869b', 175]     " 211-134-155
let s:gb.bright_aqua    = ['#8ec07c', 108]     " 142-192-124
let s:gb.bright_orange  = ['#fe8019', 208]     " 254-128-25

let s:gb.neutral_red    = ['#cc241d', 124]     " 204-36-29
let s:gb.neutral_green  = ['#98971a', 106]     " 152-151-26
let s:gb.neutral_yellow = ['#d79921', 172]     " 215-153-33
let s:gb.neutral_blue   = ['#458588', 66]      " 69-133-136
let s:gb.neutral_purple = ['#b16286', 132]     " 177-98-134
let s:gb.neutral_aqua   = ['#689d6a', 72]      " 104-157-106
let s:gb.neutral_orange = ['#d65d0e', 166]     " 214-93-14

let s:gb.faded_red      = ['#9d0006', 88]      " 157-0-6
let s:gb.faded_green    = ['#79740e', 100]     " 121-116-14
let s:gb.faded_yellow   = ['#b57614', 136]     " 181-118-20
let s:gb.faded_blue     = ['#076678', 24]      " 7-102-120
let s:gb.faded_purple   = ['#8f3f71', 96]      " 143-63-113
let s:gb.faded_aqua     = ['#427b58', 66]      " 66-123-88
let s:gb.faded_orange   = ['#af3a03', 130]     " 175-58-3
</kolory>

# Wypisz listę w postaci pliku css z deninicjami klas dla każdego elementu składni języka haskell tak jak w podanym przykładzie example. 
# Jeżeli brakuje jakiegoś elementu składni języka to dodaj nową klasę.
<important>
 - opisowe nazwy elementu jezyka hasakell takie jak keyword czy type dodaj w komentarzach!
 -  jako nazwy klas użyj nazw c1 c2 c3 c4 c5 c6 c7 c8 c9 c10 c11 c12 c13 c14 c15 c16
 - postaraj się zachować porządek nazw clas i  opisów DOKŁADNIE tak jak w przykładzie example.
- postaraj się zachować kolejność poczczególnych elementów języka tak jak w przykładzie example, czyli po kolei: type, keyword, identifier, comment, string, number, class, function, ... dalej dodawaj nowe klasy stylów
 - wszystkie typy słów kluczowych keyword zgrupuj razem
</important>

<example>
*/

/* 
.nav-link:focus {
 
   background-color: #FCFEE8;  ${czubajHighLight};
} */
